import React from "react";
import { NavLink } from "react-router-dom";
import SideContent from "./SideContent";
import { useLanguage } from "../contexts/LanguageContext";
import { translations } from "../translations/translations";

function Sidebar() {
  const { language } = useLanguage();
  const t = translations[language];

  const navLinkClass = ({ isActive }) =>
    `flex items-center px-4 py-2 rounded-xl transition-colors duration-200 ${
      isActive
        ? "bg-[#EEEEEE] text-black"
        : "text-gray-600 hover:bg-gray-100 hover:text-black"
    }`;

  return (
    <div className="w-64 bg-white border-r border-gray-200 flex flex-col h-full">
      <div className="flex-grow overflow-y-auto">
        <div className="sticky top-0 bg-white z-10 p-2">
          <NavLink to="/">
            <img
              src="/logoGray.png"
              alt="Woody's Portfolio"
              className="w-full h-auto max-h-28 cursor-pointer object-contain"
            />
          </NavLink>
        </div>
        <nav className="p-2">
          <div className="flex flex-col gap-1">
            <NavLink to="/" className={navLinkClass}>
              <p className="text-sm font-medium">{t.home}</p>
            </NavLink>
            <NavLink to="/about" className={navLinkClass}>
              <p className="text-sm font-medium">{t.careers}</p>
            </NavLink>
            <NavLink to="/articles" className={navLinkClass}>
              <p className="text-sm font-medium">{t.articles}</p>
            </NavLink>
            <NavLink to="/projects" className={navLinkClass}>
              <p className="text-sm font-medium">{t.projects}</p>
            </NavLink>
            <NavLink to="/vlogs" className={navLinkClass}>
              <p className="text-sm font-medium">{t.vlogs}</p>
            </NavLink>
            <NavLink to="/techs" className={navLinkClass}>
              <p className="text-sm font-medium">{t.techs}</p>
            </NavLink>
            <NavLink to="/playlists" className={navLinkClass}>
              <p className="text-sm font-medium">{t.playlists}</p>
            </NavLink>
            <NavLink to="/contact" className={navLinkClass}>
              <p className="text-sm font-medium">{t.contact}</p>
            </NavLink>
          </div>
        </nav>
      </div>
      <div className="mt-auto">
        <SideContent />
      </div>
    </div>
  );
}

export default Sidebar;
