export const translations = {
  en: {
    aboutMe: "About me",
    professionalExperience: "Professional Experience",
    education: "Education",
    skillsTechnologies: "Skills & Technologies",
    addNewExperience: "Add New Experience",
    addNewEducation: "Add New Education",
    addNewSkill: "Add New Skill",
    editExperience: "Edit Experience",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    remove: "Remove",
    viewProjects: "View Projects",
    viewArticles: "View Articles",
    featuredProjects: "Featured Projects",
    greeting: "Hi, I'm Woody",
    subtitle: "Passion for Robotics and Blockchain",
    introStrong: "Hi, I'm Woody, a former robotics engineer",
    introPart1: "who has transitioned into a",
    introPart2: "blockchain developer",
    introPart3: "with 2 years of experience. I also work as a",
    introPart4: "full-stack developer",
    shortDescriptionExtra:
      "As a robotics engineer, I gained extensive experience in designing and developing advanced robotic systems aimed at improving efficiency and accuracy across various industries...",
    showLess: "Show Less",
    readMore: "Read More",
    latestVlogs: "Latest Vlogs",
    latestTechReviews: "Latest Tech Reviews",
    latestPlaylists: "Latest Playlists",
    roboticsEngineerExperience:
      "As a robotics engineer, I gained extensive experience in",
    designingAndDeveloping: "designing and developing advanced robotic systems",
    aimedAt:
      "aimed at improving efficiency and accuracy across various industries. My work involved creating robots capable of performing",
    complexTasks: "complex tasks with precision.",
    journeyNewDirection:
      "However, my journey took a new direction when I discovered the",
    revolutionaryPotential: "revolutionary potential of blockchain technology.",
    ideaOf: "The idea of a",
    decentralizedLedger:
      "decentralized, secure, and transparent digital ledger",
    fascinatedMe: "fascinated me. I was convinced that blockchain could",
    transformIndustries: "transform numerous industries",
    justAsRobotics: "just as robotics had done.",
    convictionDroveMe:
      "This conviction drove me to transition into blockchain development, where I now focus on",
    buildingDApps:
      "building decentralized applications (DApps) and smart contracts.",
    withMyBackground:
      "With my background in robotics and my current expertise in blockchain, I bring a",
    uniquePerspective: "unique perspective",
    toDevelopmentProcess: "to the development process.",
    mySkillsInBoth: "My skills in both",
    frontEndAndBackEnd: "front-end and back-end development",
    allowMeToBuild: "allow me to build",
    comprehensiveSolutions: "comprehensive, end-to-end solutions.",
    continuousLearning: "Continuous learning",
    andStayingUpdated:
      "and staying updated with the latest advancements in technology are essential to my work.",
    iAmDedicated: "I am dedicated to creating",
    innovativeSolutions: "innovative solutions in blockchain,",
    addressingNewChallenges:
      "addressing new sets of challenges and opportunities.",
    home: "Home",
    careers: "Careers",
    articles: "Articles",
    projects: "Projects",
    vlogs: "Vlogs",
    techs: "Techs",
    playlists: "Playlists",
    contact: "Contact",
    latestArticles: "Latest Articles",
    visitorCount: "Visitor Count",
    total: "Total",
    todaysTotal: "Today's total",
    yourVisitsToday: "Your visits today",
  },
  ko: {
    aboutMe: "자기소개",
    professionalExperience: "경력",
    education: "학력",
    skillsTechnologies: "기술 및 스킬",
    addNewExperience: "새 경력 추가",
    addNewEducation: "새 학력 추가",
    addNewSkill: "새 기술 추가",
    editExperience: "경력 수정",
    cancel: "취소",
    save: "저장",
    edit: "수정",
    remove: "삭제",
    viewProjects: "프로젝트 보기",
    viewArticles: "아티클 보기",
    featuredProjects: "주요 프로젝트",
    greeting: "안녕하세요, 저는 우디입니다",
    subtitle: "로보틱스와 블록체인에 대한 열정",
    introStrong: "안녕하세요, 저는 우디입니다. 전 로보틱스 엔지니어였던",
    introPart1: "제가 이제는",
    introPart2: "블록체인 개발자로",
    introPart3: "전향하여 2년의 경력을 쌓았습니다. 또한 저는",
    introPart4: "풀스택 개발자로도",
    shortDescriptionExtra:
      "로보틱스 엔지니어로서, 저는 다양한 산업 분야에서 효율성과 정확성을 향상시키기 위한 고급 로봇 시스템을 설계하고 개발하는 데 광범위한 경험을 쌓았습니다...",
    showLess: "간략히 보기",
    readMore: "더 보기",
    latestVlogs: "최신 브이로그",
    latestTechReviews: "최신 기술 리뷰",
    latestPlaylists: "최신 플레이리스트",
    roboticsEngineerExperience: "로보틱스 엔지니어로서, 저는",
    designingAndDeveloping: "고급 로봇 시스템을 설계하고 개발하는 데",
    aimedAt:
      "다양한 산업 분야에서 효율성과 정확성을 향상시키기 위한 광범위한 경험을 쌓았습니다. 제 작업은",
    complexTasks:
      "정밀한 복잡한 작업을 수행할 수 있는 로봇을 만드는 것을 포함했습니다.",
    journeyNewDirection:
      "하지만, 제 여정은 새로운 방향을 택했습니다. 그것은 제가",
    revolutionaryPotential: "블록체인 기술의 혁명적인 잠재력을",
    ideaOf: "발견했을 때였습니다.",
    decentralizedLedger: "분산화되고, 안전하며, 투명한 디지털 원장",
    fascinatedMe: "이라는 아이디어에 매료되었습니다. 저는 블록체인이",
    transformIndustries: "수많은 산업을 변화시킬 수 있다고",
    justAsRobotics: "확신했습니다. 마치 로보틱스가 그랬던 것처럼 말이죠.",
    convictionDroveMe:
      "이러한 확신이 저를 블록체인 개발로 전환하게 만들었고, 지금은",
    buildingDApps: "탈중앙화 애플리케이션(DApps)과 스마트 계약을 구축하는 데",
    withMyBackground:
      "집중하고 있습니다. 로보틱스 배경과 현재의 블록체인 전문 지식을 바탕으로, 저는",
    uniquePerspective: "독특한 관점을",
    toDevelopmentProcess: "개발 과정에 가져옵니다.",
    mySkillsInBoth: "제가 가진",
    frontEndAndBackEnd: "프론트엔드와 백엔드 개발",
    allowMeToBuild: "양쪽의 기술은 제가",
    comprehensiveSolutions:
      "포괄적이고 엔드투엔드 솔루션을 구축할 수 있게 해줍니다.",
    continuousLearning: "지속적인 학습과",
    andStayingUpdated:
      "최신 기술 발전에 대한 업데이트는 제 작업에 필수적입니다.",
    iAmDedicated: "저는",
    innovativeSolutions: "블록체인에서 혁신적인 솔루션을 만드는 데",
    addressingNewChallenges:
      "전념하고 있으며, 새로운 도전과 기회를 해결하고 있습니다.",
    home: "홈",
    careers: "경력",
    articles: "아티클",
    projects: "프로젝트",
    vlogs: "브이로그",
    techs: "기술",
    playlists: "플레이리스트",
    contact: "연락처",
    latestArticles: "최신 아티클",
    visitorCount: "방문자 수",
    total: "전체",
    todaysTotal: "오늘 전체",
    yourVisitsToday: "오늘 내 방문",
  },
};
