import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getProjects, getVlogs, getTechs, getPlaylists } from "../services/api";
import { useLanguage } from "../contexts/LanguageContext";
import { translations } from "../translations/translations";

function Home() {
  const { language } = useLanguage();
  const t = translations[language];

  const [featuredProjects, setFeaturedProjects] = useState([]);
  const [lastVlogs, setLastVlogs] = useState([]);
  const [lastTechs, setLastTechs] = useState([]);
  const [lastPlaylists, setLastPlaylists] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();
  const DEFAULT_IMAGE = "/basicImage.webp";

  useEffect(() => {
    fetchProjects();
    fetchVlogs();
    fetchTechs();
    fetchPlaylists();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await getProjects();
      setFeaturedProjects(response.data.slice(0, 5));
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    }
  };

  const fetchVlogs = async () => {
    try {
      const response = await getVlogs();
      setLastVlogs(response.data.slice(0, 3));
    } catch (error) {
      console.error("Failed to fetch vlogs:", error);
    }
  };

  const fetchTechs = async () => {
    try {
      const response = await getTechs();
      setLastTechs(response.data.slice(0, 3));
    } catch (error) {
      console.error("Failed to fetch techs:", error);
    }
  };

  const fetchPlaylists = async () => {
    try {
      const response = await getPlaylists();
      setLastPlaylists(response.data.slice(0, 3));
    } catch (error) {
      console.error("Failed to fetch playlists:", error);
    }
  };

  const handleItemClick = (type, id) => {
    navigate(`/${type}/${id}`);
  };

  // HTML 태그를 제거하고 순수 텍스트만 추출하는 함수
  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  // 텍스트를 일정 길이로 자르는 함수
  const truncateText = (text, maxLength) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const description = (
    <>
      <p>
        <strong>{t.introStrong}</strong> {t.introPart1}{" "}
        <strong>{t.introPart2}</strong> {t.introPart3}{" "}
        <strong>{t.introPart4}</strong>.
      </p>

      <p>
        {t.roboticsEngineerExperience}{" "}
        <strong>{t.designingAndDeveloping}</strong> {t.aimedAt} {t.complexTasks}
      </p>

      <p>
        {t.journeyNewDirection} <strong>{t.revolutionaryPotential}</strong>{" "}
        {t.ideaOf} <strong>{t.decentralizedLedger}</strong> {t.fascinatedMe}{" "}
        <strong>{t.transformIndustries}</strong>, {t.justAsRobotics}
      </p>

      <p>
        {t.convictionDroveMe} <strong>{t.buildingDApps}</strong>{" "}
        {t.withMyBackground} <strong>{t.uniquePerspective}</strong>{" "}
        {t.toDevelopmentProcess}
      </p>

      <p>
        {t.mySkillsInBoth} <strong>{t.frontEndAndBackEnd}</strong>{" "}
        {t.allowMeToBuild} <strong>{t.comprehensiveSolutions}</strong>.{" "}
        <strong>{t.continuousLearning}</strong> {t.andStayingUpdated}
      </p>

      <p>
        {t.iAmDedicated} <strong>{t.innovativeSolutions}</strong>{" "}
        {t.addressingNewChallenges}
      </p>
    </>
  );

  const shortDescription = (
    <p>
      <strong>{t.introStrong}</strong> {t.introPart1}{" "}
      <strong>{t.introPart2}</strong> {t.introPart3}{" "}
      <strong>{t.introPart4}</strong>. {t.shortDescriptionExtra}
    </p>
  );

  return (
    <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
      <div className="@container">
        <div className="flex flex-col gap-6 px-4 py-10 @[480px]:gap-8 @[864px]:flex-row @[864px]:items-start">
          <div className="w-full @[480px]:w-[400px] @[864px]:w-[400px] flex-shrink-0">
            <div
              className="w-full h-[400px] bg-center bg-no-repeat bg-cover rounded-xl"
              style={{
                backgroundImage: 'url("/IMG_3422.jpg")',
              }}
            ></div>
          </div>
          <div className="flex flex-col gap-6 @[480px]:gap-8 flex-grow">
            <div className="flex flex-col gap-2 text-left">
              <h1 className="text-black text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em]">
                {t.greeting}
              </h1>
              <h2 className="text-[#6B6B6B] text-xl font-semibold">
                {t.subtitle}
              </h2>
              <h3 className="text-[#6B6B6B] text-sm font-normal leading-normal @[480px]:text-base @[480px]:font-normal @[480px]:leading-normal">
                {showFullDescription ? description : shortDescription}
              </h3>
              <div className="flex justify-end mt-2">
                <button
                  onClick={() => setShowFullDescription(!showFullDescription)}
                  className="px-4 py-2 bg-[#EEEEEE] text-black text-sm font-bold rounded-xl hover:bg-gray-300 transition-colors duration-200"
                >
                  {showFullDescription ? t.showLess : t.readMore}
                </button>
              </div>
            </div>
            <div className="flex-wrap gap-3 flex mt-auto">
              <button
                className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 @[480px]:h-12 @[480px]:px-5 bg-black text-[#FFFFFF] text-sm font-bold leading-normal tracking-[0.015em] @[480px]:text-base @[480px]:font-bold @[480px]:leading-normal @[480px]:tracking-[0.015em] hover:bg-gray-800 transition-colors duration-200"
                onClick={() => navigate("/projects")}
              >
                <span className="truncate">{t.viewProjects}</span>
              </button>
              <button
                className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 @[480px]:h-12 @[480px]:px-5 bg-[#EEEEEE] text-black text-sm font-bold leading-normal tracking-[0.015em] @[480px]:text-base @[480px]:font-bold @[480px]:leading-normal @[480px]:tracking-[0.015em] hover:bg-gray-300 transition-colors duration-200"
                onClick={() => navigate("/articles")}
              >
                <span className="truncate">{t.viewArticles}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
        {t.featuredProjects}
      </h2>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
        {featuredProjects.map((project) => (
          <div
            key={project.id}
            className="flex flex-col gap-3 pb-3 cursor-pointer"
            onClick={() => handleItemClick("projects", project.id)}
          >
            <div
              className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
              style={{
                backgroundImage: `url("${project.image || DEFAULT_IMAGE}")`,
              }}
            ></div>
            <div>
              <p className="text-black text-base font-medium leading-normal">
                {project.title}
              </p>
              <p className="text-[#6B6B6B] text-sm font-normal leading-normal">
                {truncateText(stripHtml(project.description), 100)}
              </p>
            </div>
          </div>
        ))}
      </div>

      <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
        {t.latestVlogs}
      </h2>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
        {lastVlogs.map((vlog) => (
          <div
            key={vlog.id}
            className="flex flex-col gap-3 cursor-pointer"
            onClick={() => handleItemClick("vlogs", vlog.id)}
          >
            <div
              className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
              style={{
                backgroundImage: `url("${vlog.image || DEFAULT_IMAGE}")`,
              }}
            ></div>
            <div>
              <p className="text-black text-base font-medium leading-normal">
                {vlog.title}
              </p>
              <p className="text-[#6B6B6B] text-sm font-normal leading-normal">
                {truncateText(stripHtml(vlog.description || vlog.content), 100)}
              </p>
            </div>
          </div>
        ))}
      </div>

      <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
        {t.latestTechReviews}
      </h2>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
        {lastTechs.map((tech) => (
          <div
            key={tech.id}
            className="flex flex-col gap-3 cursor-pointer"
            onClick={() => handleItemClick("techs", tech.id)}
          >
            <div
              className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
              style={{
                backgroundImage: `url("${tech.image || DEFAULT_IMAGE}")`,
              }}
            ></div>
            <div>
              <p className="text-black text-base font-medium leading-normal">
                {tech.title}
              </p>
              <p className="text-[#6B6B6B] text-sm font-normal leading-normal">
                {truncateText(stripHtml(tech.description || tech.content), 100)}
              </p>
            </div>
          </div>
        ))}
      </div>

      <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
        {t.latestPlaylists}
      </h2>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
        {lastPlaylists.map((playlist) => (
          <div
            key={playlist.id}
            className="flex flex-col gap-3 cursor-pointer"
            onClick={() => handleItemClick("playlists", playlist.id)}
          >
            <div
              className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
              style={{
                backgroundImage: `url("${playlist.image || DEFAULT_IMAGE}")`,
              }}
            ></div>
            <div>
              <p className="text-black text-base font-medium leading-normal">
                {playlist.title}
              </p>
              <p className="text-[#6B6B6B] text-sm font-normal leading-normal">
                {truncateText(
                  stripHtml(playlist.description || playlist.content),
                  100
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
