import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { LanguageProvider } from "./contexts/LanguageContext"; // Add this line
import Home from "./pages/Home";
import About from "./pages/about/About";
import Projects from "./pages/Projects";
import Articles from "./components/Article";
import Vlogs from "./pages/Vlog/Vlogs";
import Techs from "./pages/TechBlog/Techs";
import Playlists from "./pages/PlayList/PlayLists";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Admin/Dashboard";
import Sidebar from "./components/Sidebar";
import WalletConnect from "./components/WalletConnect";
import Search from "./components/Search";
import FullScreenEditor from "./pages/FullScreenEditor";
import ProjectDetail from "./pages/ProjectDetail";
import ArticleDetail from "./components/ArticleDetail";
import VlogDetail from "./pages/Vlog/VlogsDetail";
import TechDetail from "./pages/TechBlog/TechsDetail";
import PlaylistDetail from "./pages/PlayList/PlayListDetail";
import SearchResults from "./pages/Search/SearchResults";
import { useLanguage } from "./contexts/LanguageContext";

const ProtectedRoute = ({ children }) => {
  const { token } = useAuth();
  if (!token) {
    return <Navigate to="/" replace />;
  }
  return children;
};

function LanguageToggle() {
  const { language, toggleLanguage } = useLanguage();

  return (
    <button
      onClick={toggleLanguage}
      className="px-4 py-2 bg-black text-white text-sm font-bold rounded-full hover:bg-gray-800 transition-colors duration-200"
    >
      {language === "en" ? "한국어" : "English"}
    </button>
  );
}

function Layout({ children }) {
  return (
    <div className="flex justify-center min-h-screen bg-gray-100">
      <div className="flex w-full max-w-[1440px] bg-white shadow-lg relative">
        <div className="w-72 fixed top-0 left-auto h-screen overflow-y-auto bg-white border-r border-gray-200">
          <Sidebar />
        </div>
        <div className="flex-1 ml-72 flex flex-col">
          <header className="sticky top-0 z-10 bg-white shadow-sm">
            <div className="flex justify-between items-center p-4">
              <div className="w-64">
                <Search />
              </div>
              <div className="flex items-center gap-4">
                <LanguageToggle />
                <WalletConnect />
              </div>
            </div>
          </header>
          <main className="flex-1 overflow-y-auto">{children}</main>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:id" element={<ProjectDetail />} />
              <Route path="/articles" element={<Articles />} />
              <Route path="/articles/:id" element={<ArticleDetail />} />
              <Route path="/vlogs" element={<Vlogs />} />
              <Route path="/vlogs/:id" element={<VlogDetail />} />
              <Route path="/techs" element={<Techs />} />
              <Route path="/techs/:id" element={<TechDetail />} />
              <Route path="/playlists" element={<Playlists />} />
              <Route path="/playlists/:id" element={<PlaylistDetail />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route
                path="/editor/new"
                element={
                  <ProtectedRoute>
                    <FullScreenEditor mode="new" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/editor/edit/:id"
                element={
                  <ProtectedRoute>
                    <FullScreenEditor mode="edit" />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Layout>
        </Router>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;
